import * as React from 'react';
import * as Redux from 'redux';
import * as io from 'socket.io-client';

import * as Net from '../shared/net';
import * as Rt from '../shared/types';
import * as Async from './async';
import { config } from './config';
import { ActionTypes, setUser, addChat, setGame, patchGame, reset, pushModal, popModal, patchGameHost, setGamesList, socketStatus } from './actions';
import { State, ModalErrorType, ModalConfirmType, ModalAlertType } from './state';
import { Runtype } from 'runtypes';
import { TriviaApi } from './api';
import { TriviaStore } from './setup';



export class Client {
	protected readonly store: Redux.Store<State, ActionTypes>;
	protected readonly api: TriviaApi;
	private socket: SocketIOClient.Socket | null;
	private expectDisconnect = false;

	public constructor(store: TriviaStore) {
		this.store = store;
		this.socket = null;
		let self = this;
		this.api = new TriviaApi((res, handleReqErrLocally) => {
			if (res.type !== Async.RequestError || !handleReqErrLocally) {
				self.handleFetchError(res);
			}
		});
	}

	protected handleFetchError(err: Async.AsyncError) {
		// TODO: proper error handling, distinguish between bad request and actual network error ideally
		console.error("ErrorType: " + err.type);
		if (err.message)
			console.error("Message: " + err.message);
		if (err.exception)
			console.error(err.exception);

		if (err.type === Async.NetworkError)
			this.modalError("There was a network error contacting the server.");
		else if (err.type === Async.RequestError)
			this.modalError("Error: ", err.message);
		else /*if (err.type === Async.AppError)*/ {
			this.modalError("Woops! Somethings gone wrong.", err.message);
		}

		if (err.type != Async.RequestError && err.type != Async.NetworkError) {
			let error = "<none>";
			let stack = "<none>";
			if (err.exception) {
				let e: Error = err.exception;
				error = (e.name || 'Error') + " - " + e.message;
				stack = e.stack ?? "<none>";
			}

			this.api.reportError({
				type: err.type,
				message: err.message ?? "<none>",
				error: error,
				stack: stack,
				user: this.tryGetUser(),
				userAgent: this.getUserAgent(),
			});
		}
	}

	private tryGetUser() {
		try {
			return this.store.getState().user?.email ?? "<none>";
		} catch (err) {
			return "<err>";
		}
	}

	protected handleError(type: Async.ErrType, message: string, exception?: any) {
		this.handleFetchError({ ok: false, type, message, exception });
	}

	public handleReactError(error: Error, info: React.ErrorInfo) {
		console.error(info);
		this.handleFetchError({
			type: Async.AppError,
			ok: false,
			message: "Please refresh the page.",
			exception: error,
		});
	}

	protected disconnectSockets() {
		if (this.socket) {
			this.socket.disconnect();
			this.socket = null;
		}
	}

	// TODO: this can return a promise that gets resolved/rejected during socket callbacks
	// then it can be properly awaited, if we want to get really fancy we could resolve
	// on a specific event i.e 'set_game'
	protected connectSockets() {
		if (this.socket)
			return;

		this.socket = io.connect("/", { path: "/api/sockets" });

		let self = this;

		// ---- Connection ----
		let debounceTimer: NodeJS.Timeout | undefined;
		const showSocketErr = () => {
			if (!debounceTimer)
				debounceTimer = setTimeout(() => self.store.dispatch(socketStatus(false)), config.socketDebounceTime);
		}

		const hideSocketErr = () => {
			if (debounceTimer) {
				clearTimeout(debounceTimer);
				debounceTimer = undefined;
			}
			self.store.dispatch(socketStatus(true));
		}

		const handleConnectErr = (err: any) => {
			self.disconnectSockets();
			self.handleError(Async.NetworkError, "Error establishing connection with server", err);
		}

		const onConnectErr = (err: any) => handleConnectErr(err);
		const onConnectTimeout = () => handleConnectErr(new Error("socket connection timeout"));

		this.socket.once('connect_error', onConnectErr);
		this.socket.once('connect_timeout', onConnectTimeout);

		this.socket.once('connect', () => {
			hideSocketErr();
			self.socket!.off('connect_error', onConnectErr);
			self.socket!.off('connect_timeout', onConnectTimeout);
		});

		// ---- Re/Dis Connect ----
		this.socket.on('disconnect', (reason: string) => {
			console.log('[SIO] - disconnect');
			console.log('reason: ' + reason);
			if (reason === 'transport error' || reason === 'transport close' || reason === 'ping timeout')
				showSocketErr();
			else if (reason === 'io server disconnect') {
				if (self.socket && !this.expectDisconnect)
					self.handleError(Async.AppError, "Connection forcibly closed by server");
			} else if (reason === 'io client disconnect') {
				// Nothing to see here
			}
			else {
				self.handleError(Async.AppError, "SIO disconnected for unknown reason: " + reason);
			}
		});

		this.socket.on('reconnecting', () => {
			console.log('[SIO] - reconnecting');
			showSocketErr();
		});

		this.socket.on('reconnect', () => {
			console.log('[SIO] - reconnect');
			hideSocketErr();
		});

		this.socket.on('error', (err: any) => {
			console.log('[SIO] - error');
			console.log(err);
			// This may be problematic
			//this.handleError(Async.AppError, "sio error", err);
		});

		this.socket.on('reconnect_failed', (err: any) => {
			console.log('[SIO] - reconnect_failed');
		});

		this.socket.on('reconnect_error', (err: any) => {
			// could be once per reconnect atempt
			console.log('[SIO] - reconnect_error');
		});


		// ---- Data ----
		function registerSocketEvent<T>(event: string, rt: Runtype<T>, cb: (t: T) => void) {
			self.socket!.on(event, function (data: {}) {
				if (rt.guard(data)) {
					if (config.logVerbose) {
						console.log(`[SIO] - ${event}`);
						console.log(data);
					}
					cb(data);
				} else {
					self.handleError(Async.AppError, `[SIO] - ${event}: ${Async.rtFailMsg(data, rt)}`);
					console.log(data);
				}
			});
		}

		registerSocketEvent(Net.Action.Chat, Rt.Chat, (chat) => {
			self.store.dispatch(addChat(chat));
		});

		registerSocketEvent(Net.Action.Alert, Rt.UserAlert, (alert) => {
			self.modalAlert(alert.message);
		});

		registerSocketEvent(Net.Action.SetGame, Rt.Game, (game) => {
			self.store.dispatch(setGame(game));
		});

		registerSocketEvent(Net.Action.PatchGame, Rt.Patch, (patch) => {
			self.store.dispatch(patchGame(patch));
		});

		registerSocketEvent(Net.Action.EndGame, Rt.Empty, (patch) => {
			self.disconnectSockets();
			self.store.dispatch(setGame(null));
			self.onGameEnd();

			// TODO: something more appropriate
			self.modalAlert('game has ended');
		});

		// Host actions, here for now
		registerSocketEvent(Net.Action.PatchHost, Rt.GameHostPatch, (patch) => {
			self.store.dispatch(patchGameHost(patch));
		});
	}

	public modalError(msg: string, sub?: string) {
		let self = this;
		return new Promise(resolve => {
			self.store.dispatch(pushModal({
				type: ModalErrorType,
				main: msg,
				sub: sub,
				onClose: () => {
					self.store.dispatch(popModal());
					resolve();
				}
			}))
		});
	}

	public modalAlert(msg: string) {
		let self = this;
		return new Promise(resolve => {
			self.store.dispatch(pushModal({
				type: ModalAlertType,
				msg: msg,
				onClose: () => {
					self.store.dispatch(popModal());
					resolve();
				}
			}))
		});
	}

	public async modalConfirm(msg: string): Promise<boolean> {
		let self = this;
		return new Promise(resolve => {
			let onClose = (c: boolean) => {
				self.store.dispatch(popModal());
				resolve(c);
			}

			self.store.dispatch(pushModal({
				type: ModalConfirmType,
				msg: msg,
				confirm: () => onClose(true),
				deny: () => onClose(false),
			}));
		});
	}

	public async initialize() {
		// TODO: would be nice if we could know ahead of time if this would fail
		// TODO: could use ejs: https://scotch.io/tutorials/use-ejs-to-template-your-node-application

		let res = await this.api.getUser();
		if (res.ok)
			this.setUser(res.response);
		else
			this.store.dispatch(setUser(null));

		// Debug controls for now
		if (config.DEV && config.debugCtrls) {
			let self = this;
			let onKeyDown = (e: KeyboardEvent) => {
				if (e.key === '`')
					self.store.dispatch(socketStatus(self.store.getState().socketErr));
				else if (e.key === '1')
					self.modalAlert('this is an alert');
				else if (e.key === '2')
					self.modalError('this is an error', 'this is sub text');
				else if (e.key === '3')
					self.modalConfirm('this is a confirmation');
				else if (e.key === '4')
					self.reportDummyError();
			}

			document.addEventListener("keydown", onKeyDown, false);
		}
	}

	private reportDummyError() {
		function internalGenerateDummyError() {
			return new Error("dummy error");
		}

		let err = internalGenerateDummyError();
		this.handleError(Async.AppError, "test error message", err);
	}

	private setUser(user: Net.User) {
		this.store.dispatch(setUser(user));
		this.onLoggedIn(user);
	}

	/* virtual */protected async onLoggedIn(user: Net.User) { }
	/* virtual */protected onGameEnd() { }

	public async loadGamesList() {
		let res = await this.api.getGamesList();
		if (res.ok)
			this.store.dispatch(setGamesList(res.response));
	}

	public sendChat(msg: string, isTeam: boolean) {
		return this.api.sendChat({ msg }, isTeam);
	}

	public async joinGame(gameId: string) {
		let res = await this.api.joinGame({ gameId: gameId });
		// TODO: should probably patch user
		if (res.ok)
			await this.connectSockets();

		return res;
	}

	public async leaveGame() {
		this.expectDisconnect = true;
		let res = await this.api.leaveGame();
		this.expectDisconnect = false;

		this.disconnectSockets();
		this.store.dispatch(setGame(null));

		return res;
	}

	private getUserAgent() {
		return window.navigator.userAgent || "<none>";
	}

	public async register(
		email: string,
		password: string,
		username: string,
		initials: string
	) {
		let credentials = {
			username: email,
			password: password,
			name: username,
			initials: initials,
			userAgent: this.getUserAgent(),
		};

		let res = await this.api.register(credentials);
		if (res.ok)
			this.setUser(res.response);

		return res;
	}

	public async login(email: string, password: string) {
		let credentials = {
			username: email,
			password: password,
			userAgent: this.getUserAgent(),
		};

		let res = await this.api.login(credentials);
		if (res.ok)
			this.setUser(res.response);

		return res;
	}

	public async logout() {
		let res = await this.api.logout();
		if (res.ok) {
			this.disconnectSockets();
			this.store.dispatch(reset());
		}

		return res;
	}

	public async changeUserName(name: string, initials: string) {
		let res = await this.api.changeUserName({ name, initials });
		if (res.ok)
			this.setUser(res.response);

		return res;
	}

	// TODO:
	// team/setname
	// team/setowner
	// team/setpass
}

export const ClientContext = React.createContext<Client | undefined>(undefined);