import * as Net from '../shared/net';


export interface Game {
	phase: Net.Phase;
	config: Net.GameConfigPublic;
	team: Net.Team | null;
	teams: Map<string, Net.TeamMeta>;

	gameChat: Net.Chat[];
	teamChat: Net.Chat[];
}

export const ModalConfirmType = 'confirm';
export interface ModalConfirm {
	type: typeof ModalConfirmType;

	msg: string;
	confirm: () => void;
	deny: () => void;
}

export const ModalAlertType = 'alert';
export interface ModalAlert {
	type: typeof ModalAlertType;

	msg: string;
	onClose: () => void;
}

export const ModalErrorType = 'error';
export interface ModalError {
	type: typeof ModalErrorType;

	main: string;
	sub?: string;
	onClose: () => void;
}

export type ModalContent =
	ModalError |
	ModalAlert |
	ModalConfirm;

export interface GameHost {
	running: boolean;
	config: Net.GameConfig | null;
	teams: Map<string, Net.Team>;
}

export interface State {
	gameId: string | null,
	initialLoad: boolean,
	socketErr: boolean,
	modals: ModalContent[];

	user: Net.User | null;
	game: Game | null;

	gamesList: Net.GameMeta[] | null;

	gameHost: GameHost;
}


// TODO: make this better somehow i.e from server
export const initialState: State = {
	initialLoad: true,
	gameId: null,
	socketErr: false,
	modals: [],
	user: null,
	game: null,
	gamesList: null,
	gameHost: {
		running: false,
		config: null,
		teams: new Map<string, Net.Team>(),
	}
};