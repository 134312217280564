import * as Net from '../shared/net';
import { ModalContent } from './state';


export const ActionTypeSetUser = 'set_user';
export const ActionTypeSetGame = 'set_game';
export const ActionTypePatchGame = 'patch_game';
export const ActionTypeAddChat = 'add_chat';
export const ActionTypePatchGameHost = 'patch_game_host';
export const ActionTypePatchGameConfig = 'patch_game_config';
export const ActionTypeSetGamesList = 'set_games_list';
export const ActionTypeReset = 'reset';
export const ActionTypePushModal = 'push_modal';
export const ActionTypePopModal = 'pop_modal';
export const ActionTypeSocketStatus = 'socket_status';
export const ActionTypeSetGameId = 'set_game_id';

interface SetUserAction {
	type: typeof ActionTypeSetUser;
	user: Net.User | null;
}

export const setUser = (user: Net.User | null): ActionTypes => ({
	type: ActionTypeSetUser,
	user: user
});

interface AddChatAction {
	type: typeof ActionTypeAddChat;
	chat: Net.Chat;
}

export const addChat = (chat: Net.Chat): ActionTypes => ({
	type: ActionTypeAddChat,
	chat: chat
});

interface SetGameAction {
	type: typeof ActionTypeSetGame;
	game: Net.Game | null;
}

export const setGame = (game: Net.Game | null): ActionTypes => ({
	type: ActionTypeSetGame,
	game: game
});

export interface PatchGameAction {
	type: typeof ActionTypePatchGame;
	patch: Net.Patch;
}

export const patchGame = (patch: Net.Patch): ActionTypes => ({
	type: ActionTypePatchGame,
	patch: patch
});

export interface PatchGameHostAction {
	type: typeof ActionTypePatchGameHost;
	patch: Net.GameHostPatch;
}

export const patchGameHost = (patch: Net.GameHostPatch): ActionTypes => ({
	type: ActionTypePatchGameHost,
	patch: patch,
});

export interface PatchGameConfigAction {
	type: typeof ActionTypePatchGameConfig,
	patch: Net.GameConfigPatch,
}

export const patchGameConfig = (patch: Net.GameConfigPatch): ActionTypes => ({
	type: ActionTypePatchGameConfig,
	patch: patch,
});

export interface SetGamesListAction {
	type: typeof ActionTypeSetGamesList;
	gamesList: Net.GameMeta[];
}

export const setGamesList = (gamesList: Net.GameMeta[]): ActionTypes => ({
	type: ActionTypeSetGamesList,
	gamesList: gamesList
});

export interface ResetAction {
	type: typeof ActionTypeReset
}

export const reset = (): ActionTypes  => ({
	type: ActionTypeReset
});

export interface PushModalAction {
	type: typeof ActionTypePushModal;
	modal: ModalContent;
}

export const pushModal = (modal: ModalContent): ActionTypes => ({
	type: ActionTypePushModal,
	modal: modal,
});

export interface PopModalAction {
	type: typeof ActionTypePopModal;
}

export const popModal = (): ActionTypes => ({
	type: ActionTypePopModal,
});

export interface SocketStatusAction {
	type: typeof ActionTypeSocketStatus;
	ok: boolean;
}

export const socketStatus = (status: boolean): ActionTypes => ({
	type: ActionTypeSocketStatus,
	ok: status,
});

export interface SetGameIdAction {
	type: typeof ActionTypeSetGameId;
	id: string | null;
}

export const setGameId = (id: string | null): ActionTypes => ({
	type: ActionTypeSetGameId,
	id: id,
});

export type ActionTypes =
	SetUserAction |
	AddChatAction |
	SetGameAction |
	PatchGameAction |
	PatchGameHostAction |
	PatchGameConfigAction |
	SetGamesListAction |
	ResetAction |
	PushModalAction |
	PopModalAction |
	SocketStatusAction |
	SetGameIdAction;
