import * as React from 'react';

import * as Net from '../shared/net';
import * as Async from './async';
import { Client } from './context';
import { patchGame } from './actions';


export class TriviaClient extends Client {
	protected async onLoggedIn(user: Net.User) {
		if (!user.currentGame)
			this.loadGamesList();
		else if (user.currentGame)
			await this.connectSockets();
	}

	protected onGameEnd() {
		this.loadGamesList();
	}

	public async leaveGame() {
		let state = this.store.getState();
		let team = state.game?.team;

		let c = true;
		if (team) {
			// TODO:AL11
			if (team.owner?.id === state.user!.id) {
				if (team.players.length > 1) {
					c = await this.modalConfirm("Are you sure you want to leave this game? Team ownership will be passed to another member");
				}
				else {
					c = await this.modalConfirm("Are you sure you want to leave this game? Your team will be forfeit");
				}
			} else {
				c = await this.modalConfirm("Are you sure you want to leave this game? You will be removed from your team");
			}
		}

		if (!c)
			return Async.CancelError;

		let res = await super.leaveGame();
		if (res.ok)
			this.loadGamesList();
		return res;
	}

	public createTeam(name: string, pass: string) {
		let create: Net.CreateTeam = { teamName: name };
		if (pass)
			create.teamPass = pass;

		return this.api.createTeam(create);
	}

	public joinTeam(id: string, pass: string) {
		let join: Net.JoinTeam = { teamId: id };
		if (pass)
			join.teamPass = pass;

		return this.api.joinTeam(join);
	}

	public async leaveTeam(user: Net.User, team: Net.Team) {
		if (team.owner?.id === user.id) {
			if (team.players.length > 1) {
				await this.modalAlert("You cannot leave a team you own without reassigning ownership first");
				return Async.CancelError;
			} else {
					// TODO:AL7
				let c = await this.modalConfirm('are you sure you want to forfeit your team?');
				if (!c)
					return Async.CancelError;
			}
		}

		let res = await this.api.leaveTeam();
		if (res.ok)
			this.store.dispatch(patchGame({
				team: null,
			}));

		return res;
	}

	public async setTeamOwner(user: Net.UserMeta) {
		let c = await this.modalConfirm(`Are you sure you want to make ${user.name} the new team captain?`);
		if (!c)
			return Async.CancelError;

		return this.api.setTeamOwner({ owner: user.id });
	}

	public setTeamLFG(isLFG: boolean) {
		return this.api.setTeamLFG({ isLFG });
	}

	public submitAnswer(qid: Net.QuestionId, answer: string) {
		return this.api.submitAnswer({
			...qid,
			answer: answer
		});
	}

	public submitGrade(qid: Net.QuestionId, correct: boolean) {
		return this.api.submitGrade({
			...qid,
			correct: correct
		});
	}

	public submitComplaint(qid: Net.QuestionId) {
		return this.api.submitComplaint(qid);
	}

	public submitRound(submit: Net.SubmitRound) {
		return this.api.submitRound(submit);
	}
}

export const TriviaContext = React.createContext<TriviaClient | undefined>(undefined);