import { Runtype } from "runtypes";

export const NetworkError = 'error-network';
export const RequestError = 'error-request';
export const AppError = 'error-app';
export const Cancel = 'cancel';

export type ErrType =
	typeof NetworkError |
	typeof RequestError |
	typeof Cancel		|
	typeof AppError;

export interface AsyncError {
	ok: false,
	type: ErrType;
	exception?: any;
	message?: string;
}

export const CancelError: AsyncError = { ok: false, type: Cancel };

export interface AsyncResult<T> {
	ok: true,
	response: T;
}

export type AsyncResponse<T> = AsyncResult<T> | AsyncError;

export function delay(ms: number) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

function resErrorMsg(method: string, url: string) {
	return `'${method}'ing ${url}`;
}

function resError(method: string, url: string, status: any) {
	return new Error(`error ${resErrorMsg(method, url)} - (${status})}`);
}

export async function fetchJsonRaw(method: string, url: string, data?: {}): Promise<AsyncResponse<{}>> {
	//if (method !== 'GET') await delay(2000);

	var info: any = {
		credentials: 'include',
		method: method,
		headers: {
			'Accept': 'application/json',
		},
	};

	if (data) {
		info.headers['Content-Type'] = 'application/json';
		info.body = JSON.stringify(data);
	}

	try {
		let res = await fetch(url, info);

		if (res.ok || res.status === 422 || res.status === 403) {
			try {
				let body = await res.json();

				if (!res.ok) {
					let msg = body.message;
					if (typeof (msg) !== 'string')
						msg = String(msg);

					return {
						ok: false,
						type: res.status === 422 ? RequestError : AppError,
						message: msg,
						exception: resError(method, url, res.status)
					}
				}

				return {
					ok: true,
					response: body
				}
			} catch (err) {
				return {
					ok: false,
					type: AppError,
					message: resErrorMsg(method, url) + ": malformed json",
					exception: err,
				}
			}
		} else {
			let type: ErrType = NetworkError;
			let appErrors = new Set([401, 403, 404, 500]);
			if (appErrors.has(res.status))
				type = AppError;

			return {
				ok: false,
				type: type,
				exception: resError(method, url, res.status)
			}
		}
	} catch (err) {
		return {
			ok: false,
			type: NetworkError,
			message: resErrorMsg(method, url) + ": network error",
			exception: err
		}
	}
}

export function rtFailMsg<T>(t: T, rt: Runtype<T>) {
	let message = "invalid data";
	let failure = rt.validate(t);
	if (!failure.success)
		message += " - " + (failure.key ?? "<unk>") + ": " + failure.message;

	return message;
}

export async function fetchJson<T>(method: string, url: string, data: {} | undefined, rt: Runtype<T>): Promise<AsyncResponse<T>> {
	let res = await fetchJsonRaw(method, url, data);
	if (!res.ok)
		return res;

	if (rt.guard(res.response)) {
		return {
			ok: true,
			response: res.response
		}
	} else {
		let message = rtFailMsg(res.response, rt);

		return {
			ok: false,
			type: AppError,
			message: resErrorMsg(method, url) + ": " + message,
		}
	}
}
