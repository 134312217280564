import * as React from 'react';


export interface FormProps {
	children?: React.ReactNode;
	className?: string;
	style?: React.CSSProperties;
	onSubmit: () => void;
}

export function Form({ children, className, style, onSubmit }: FormProps) {
	return (
		<form style={style} className={className} onSubmit={e => { e.preventDefault(); onSubmit(); }}>
			{children}
		</form>
	);
}


function FormError({ err, className }: { err?: string, className?: string }) {
	if (!err)
		return null;

	return (
		<div className={className}>
			<p>{err}</p>
		</div>
	);
}


export interface InputProps {
	label?: string,
	className?: string,
	hidden?: boolean,
	type?: string,
	placeholder?: string,
	maxLength?: number,

	validateFn?: (v: string) => string | undefined,
}

type FormInputProps = InputProps & {
	errorClass?: string,
	inval?: string,
	readOnly: boolean,
} 

const FormInput = React.forwardRef<HTMLInputElement, FormInputProps>(function FormInput(_props, ref) {
	const props = {
		type: "text",
		..._props,
	}

	if (props.hidden)
		return null;

	return <>
		{props.label && <h4>{props.label}</h4>}
		<input
			ref={ref}
			type={props.type}
			readOnly={props.readOnly}
			className={props.className}
			placeholder={props.placeholder}
			maxLength={props.maxLength}
		/>
		<FormError err={props.inval} className={props.errorClass} />
	</>
});

export interface ValidatedFormProps {
	formStyle?: React.CSSProperties,
	formClass?: string,
	inputClass?: string,
	inputWrapperClass?: string,
	errorClass?: string,
	inputs: InputProps[],
	children?: React.ReactNode,
	childrenPre?: React.ReactNode,

	globalErr?: string,
	disabled: boolean,
	submitFn: (...args: string[]) => void,
}

export function ValidatedForm(props: ValidatedFormProps) {
	let [invals, setInvals] = React.useState<(string | undefined)[]>(new Array(props.inputs.length));
	let refs = props.inputs.map(() => React.useRef<HTMLInputElement>(null));

	const onSubmit = () => {
		let args: string[] = new Array(props.inputs.length);
		let invals: (string | undefined)[] = new Array(props.inputs.length);
		let hasInval = false;

		for (let i = 0; i < props.inputs.length; ++i) {
			let val = refs[i].current?.value ?? "";
			let inputProps = props.inputs[i];

			args[i] = val;
			if (!inputProps.hidden && inputProps.validateFn) {
				let inval = inputProps.validateFn(val);
				if (inval) {
					invals[i] = inval;
					hasInval = true;
				}
			}
		}

		setInvals(invals);
		if (!hasInval)
			props.submitFn(...args);
	}

	const inputs = <>
		{props.inputs.map((input, i) => {
			let inputProps: FormInputProps = {
				className: props.inputClass,
				errorClass: props.errorClass,
				readOnly: props.disabled,
				inval: invals[i],
				...input,
			};

			return <FormInput key={i} {...inputProps} ref={refs[i]} />
		})}
		<FormError err={props.globalErr} className={props.errorClass} />
	</>;

	return (
		<Form className={props.formClass} onSubmit={onSubmit} style={props.formStyle} >
			{props.childrenPre}
			{props.inputWrapperClass
				? <div className={props.inputWrapperClass}>{inputs}</div>
				: inputs
			}
			{props.children}
		</Form>
	);
}
