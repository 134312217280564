import { Record, Union, Partial, Array, Tuple, Boolean, Number, String, Undefined, Null, Literal } from 'runtypes';

import * as Phases from './phases';
import { Integer, PosInt } from './integer';
import { isId } from './validation';


const Id = String.withConstraint((s) => isId(s));
const NEString = String.withConstraint((s) => s.trim().length > 0);

export const UserMeta = Record({
	id: Id,
	name: NEString,
	initials: NEString,
});

export const User = UserMeta.And(Record({
	email: NEString,
	isAdmin: Boolean,

	currentGame: Id.Or(Null),
	hostedGame: Id.Or(Null),
}));

export const Chat = Record({
	id: Integer,
	user: NEString,
	msg: NEString
}).And(Partial({
	system: Boolean,
	team: NEString,
}));

export const UserAlert = Record({
	message: NEString,
});

export const ComplaintPending = 'pending';
export const ComplaintReviewed = 'reviewed';

export const ComplaintStatus =
	Literal(ComplaintPending).Or(Literal(ComplaintReviewed)).Or(Null);

export const TeamAnswer = Record({
	answer: String,
	correct: Boolean.Or(Null),
	didGrade: Boolean.Or(Null),
	complaint: ComplaintStatus,
});

export const TeamScore = Record({
	points: Integer,
	penalties: Integer,
	total: Integer,
});

export const TeamMeta = Record({
	id: Id,
	owner: UserMeta.Or(Null),
	players: Array(UserMeta),

	name: NEString,
	hasPassword: Boolean,
	isLFG: Boolean,
	forfeit: Boolean,
	inactive: Boolean,
	totalScore: Integer,
	scores: Array(TeamScore),
});

export const TeamRound = Record({
	answers: Array(TeamAnswer),
	teamGraded: Id.Or(Undefined),
	teamGradedBy: Id.Or(Undefined),
	submitGrades: Boolean,
	submitReview: Boolean,
});

export const NoAssignment = 'no-assign';
export const Team = Record({
	password: String.Or(Null),
	rounds: Array(TeamRound),

	otherTeamAnswers: Array(TeamAnswer).Or(Null).Or(Literal(NoAssignment)),
}).And(TeamMeta);

export const PreGamePhase = Record({
	type: Literal(Phases.PreGame),
	startTime: Integer.Or(Null),
	showRules: Boolean,
	showPrizes: Boolean,
});

export const PostGamePhase = Record({
	type: Literal(Phases.PostGame),
	showLeaderboard: Boolean,
	showLBPlace: Integer,
});

export const SubmitPhase = Record({
	type: Literal(Phases.Submit),
	round: PosInt,
	subRound: PosInt,
}).And(
	Record({
		subPhase: Literal(Phases.SubPre).Or(Literal(Phases.SubPost)),
	}).Or(Record({
		subPhase: Literal(Phases.SubIntra),
		curQuestion: PosInt,
		shownQuestion: PosInt.Or(Null),
		showAll: Boolean,
		closeTime: Integer.Or(Null),
	}))
);

export const GradingPhase = Record({
	type: Literal(Phases.Grade),
	round: PosInt,
}).And(
	Record({
		subPhase: Literal(Phases.SubPre).Or(Literal(Phases.SubPost)),
	}).Or(Record({
		subPhase: Literal(Phases.SubIntra),
		curQuestion: PosInt,
		closeTime: Integer.Or(Null),
	}))
);

export const ReviewPhase = Record({
	type: Literal(Phases.Review),
	round: PosInt,
}).And(Partial({
	pseudoPost: Boolean
}));

export const IntermissionPhase = Record({
	type: Literal(Phases.Intermission),
	nextRound: PosInt,
	startTime: Integer.Or(Null),
	showLeaderboard: Boolean,
});

export const Phase = Union(
	PreGamePhase,
	SubmitPhase,
	GradingPhase,
	ReviewPhase,
	IntermissionPhase,
	PostGamePhase
);

export const RoundPhase = Union(
	SubmitPhase,
	GradingPhase,
	ReviewPhase
);

export const QTypeText = 'qt-text';

export const QuestionBase = Record({
	question: String,
	answer: String, // TODO: move to text type
}).And(Partial({
	image: NEString,
}));

export const QuestionText = Record({
	type: Literal(QTypeText),
}).And(QuestionBase);

export const Question = QuestionText;//.Or(QuestionImage);

export const SubRound = Record({
	title: String,
	start: PosInt,
	end: PosInt,
});

export const Round = Record({
	pointValue: PosInt,
	questions: Array(Question),
	subRounds: Array(SubRound),
});

const ConfigPrivateProps = {
}

export const StreamInfo = Record({
	url: String,
	width: Number.Or(Null),
	height: Number.Or(Null),
})

const ConfigPublicProps = {
	title: String,
	scheduledStart: Integer.Or(Null),
	stream: StreamInfo.Or(Null),
	rounds: Array(Round),
	maxTeamSize: Integer,
}

export const GameConfigPrivate = Record(ConfigPrivateProps);
export const GameConfigPublic = Record(ConfigPublicProps);
export const GameConfig = GameConfigPublic.And(GameConfigPrivate);

export const GameHostPatch = Partial({
	running: Boolean,
	config: GameConfig,
	teams: Array(Tuple(Id, Team.Or(Null))),
});

export const Game = Record({
	id: Id,
	phase: Phase,
	config: GameConfigPublic,

	team: Team.Or(Null),
	teams: Array(Tuple(Id, TeamMeta)),

	gameChat: Array(Chat),
	teamChat: Array(Chat).Or(Null),
});

export const Patch = Partial({
	phase: Phase,
	team: Team.Or(Null),
	teams: Array(Tuple(Id, TeamMeta.Or(Null)))
});

export const GameMeta = Record({
	title: String,
	id: Id
}).And(
	Record({
		active: Literal(true),
		begun: Boolean
	}).Or(Record({
		active: Literal(false),
		nextScheduled: Integer.Or(Null)
	 }))
);
export const GamesList = Array(GameMeta);

export const Empty = Record({});


// ---- To Server ----
export const QuestionId = Record({
	round: PosInt,
	question: PosInt,
});

export const SubmitAnswer = Record({
	answer: String,
}).And(QuestionId);

export const SubmitGrade = Record({
	correct: Boolean,
}).And(QuestionId);

export const SubmitComplaint = Record({
}).And(QuestionId);

export const SubmitRound = Record({
	round: PosInt,
	type: Literal(Phases.Grade).Or(Literal(Phases.Review)),
});

export const SendChat = Record({
	msg: NEString
});

export const JoinGame = Record({
	gameId: Id,
});

export const CreateTeam = Record({
	teamName: NEString
}).And(Partial({
	teamPass: NEString
}));

export const JoinTeam = Record({
	teamId: Id
}).And(Partial({
	teamPass: NEString
}));

export const SetTeamOwner = Record({
	owner: Id
});

export const SetTeamLFG = Record({
	isLFG: Boolean,
});

export const Credentials = Record({
	// Note: these are the default fields used by passport
	username: NEString,
	password: NEString,

	userAgent: String,
});

export const ChangeName = Record({
	name: NEString,
	initials: NEString,
});

export const Registration = Credentials.And(ChangeName);

export const ReportError = Record({
	type: String,
	message: String,
	error: String,
	stack: String,
	user: String,
	userAgent: String,
});

// ---- To Server: host ----
export const GameId = Record({
	gameId: Id,
});

export const GetGameHost = GameId;
export const GameConfigPatch =
	GameId
		.And(Partial(ConfigPublicProps))
		.And(Partial(ConfigPrivateProps));

export const LaunchGame = GameId;
export const CloseGame = GameId;

export const SetPhase = Record({
	phase: Phase,
}).And(GameId);

export const SubmitComplaintReview = Record({
	teamId: Id,
	correct: Boolean,
}).And(QuestionId).And(GameId);

export const SetStream = Record({
	stream: NEString,
}).And(GameId);


