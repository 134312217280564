import { Runtype } from 'runtypes';

import * as Async from './async';
import * as Net from '../shared/net';
import * as Rt from '../shared/types';

export class TriviaApi {
	// ---- Authentication ----
	public register(registration: Net.Registration) {
		return this.post('/api/register', registration, Rt.User, true);
	}

	public login(credentials: Net.Credentials) {
		return this.post('/api/login', credentials, Rt.User, true);
	}

	public logout() {
		return this.post('/api/logout', undefined, Rt.Empty);
	}

	public getUser() {
		return this.get('/api/user/get', Rt.User, true);
	}

	public changeUserName(name: Net.ChangeName) {
		return this.post('/api/user/setname', name, Rt.User, true);
	}


	// ---- Game ----
	public sendChat(chat: Net.SendChat, isTeam: boolean) {
		return this.post(`/api/${isTeam ? "team" : "game"}/chat`, chat, Rt.Empty);
	}

	public joinGame(join: Net.JoinGame) {
		return this.post('/api/game/join', join, Rt.Empty);
	}

	public leaveGame() {
		return this.post('/api/game/leave', undefined, Rt.Empty);
	}

	public createTeam(team: Net.CreateTeam) {
		return this.post('/api/team/create', team, Rt.Empty, true);
	}

	public joinTeam(join: Net.JoinTeam) {
		return this.post('/api/team/join', join, Rt.Empty, true);
	}

	public leaveTeam() {
		return this.post('/api/team/leave', undefined, Rt.Empty);
	}

	public setTeamLFG(setLFG: Net.SetTeamLFG) {
		return this.post('/api/team/setlfg', setLFG, Rt.Empty);
	}

	public setTeamOwner(owner: Net.SetTeamOwner) {
		return this.post('/api/team/setowner', owner, Rt.Empty); 
	}

	public getGamesList() {
		return this.get('/api/games/get', Rt.GamesList);
	}

	public submitAnswer(submit: Net.SubmitAnswer) {
		return this.post('/api/team/submit/answer', submit, Rt.Empty);
	}

	public submitGrade(submit: Net.SubmitGrade) {
		return this.post('/api/team/submit/grade', submit, Rt.Empty);
	}

	public submitComplaint(complaint: Net.SubmitComplaint) {
		return this.post('/api/team/submit/complaint', complaint, Rt.Empty);
	}

	public submitRound(submit: Net.SubmitRound) {
		return this.post('/api/team/submit/round', submit, Rt.Empty);
	}

	public reportError(err: Net.ReportError) {
		Async.fetchJson('POST', '/api/error', err, Rt.Empty);
	}


	// ---- Host ----
	public getGameHost(game: Net.GetGameHost) {
		return this.post('/api/host/game/get', game, Rt.GameHostPatch);
	}

	public patchGameConfig(config: Net.GameConfigPatch) {
		return this.post('/api/host/game/configure', config, Rt.Empty);
	}

	public submitComplaintReview(review: Net.SubmitComplaintReview) {
		return this.post('/api/host/complaint/review', review, Rt.Empty);
	}

	public setPhase(phase: Net.SetPhase) {
		return this.post('/api/host/phase', phase, Rt.Empty);
	}

	public updateStream(stream: Net.SetStream) {
		return this.post('/api/host/updatestream', stream, Rt.Empty);
	}

	public launchGame(launch: Net.LaunchGame) {
		return this.post('/api/host/game/launch', launch, Rt.Empty);
	}

	public closeGame(close: Net.CloseGame) {
		return this.post('/api/host/game/close', close, Rt.Empty);
	}


	// ---- Internals ----
	private readonly handleError: (res: Async.AsyncError, handleReqErrLocally: boolean) => void;

	public constructor(handleError: (res: Async.AsyncError, handleReqErrLocally: boolean) => void) {
		this.handleError = handleError;
	}

	private async fetchJson<T>(method: string, url: string, data: {} | undefined, rt: Runtype<T>, handleReqErrLocally = false) {
		let res = await Async.fetchJson(method, url, data, rt);
		if (!res.ok)
			this.handleError(res, handleReqErrLocally);

		return res;
	}

	private post<T>(url: string, data: {} | undefined, rt: Runtype<T>, handleReqErrLocally = false) {
		return this.fetchJson('POST', url, data, rt, handleReqErrLocally);
	}

	private get<T>(url: string, rt: Runtype<T>, handleReqErrLocally = false) {
		return this.fetchJson('GET', url, undefined, rt, handleReqErrLocally);
	}
}