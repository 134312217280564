import * as React from 'react';
import * as ReactRedux from 'react-redux';

import * as Net from '../shared/net';
import { State } from './state';
import { ClientContext } from './context';
import { TriviaContext } from './contextTrivia';
import { HostContext } from './contextHost';

function select<T>(fn: (state: State) => T, shallowEquality: boolean = false) {
	return ReactRedux.useSelector(fn, shallowEquality ? ReactRedux.shallowEqual : undefined);
}

export const useUser = () => select(state => state.user!);
export const useTeamOpt = () => select(state => state.game?.team ?? null);
export const useTeam = () => useTeamOpt()!;
export const usePhase = () => select(state => state.game!.phase, true);
export const useGameTitle = () => select(state => state.game!.config.title);
export const useRounds = () => select(state => state.game!.config.rounds);
export const useRound = (phase: Net.RoundPhase) => useRounds()[phase.round];
export const useTeams = () => select(state => state.game!.teams);
export const useGameChat = () => select(state => state.game!.gameChat);
export const useTeamChat = () => select(state => state.game!.teamChat);
export const useGamesList = () => select(state => state.gamesList);
export const useGameConfigOpt = () => select(state => state.game?.config);
export const useGameConfig = () => useGameConfigOpt()!;

export const useHasGame = () => select(state => !!state.game);
export const useHasTeam = () => select(state => !!state.game?.team);
export const useHasConnectionErr = () => select(state => state.socketErr);

export const useHasGameId = () => select(state => !!state.gameId);
export const useGameHost = () => select(state => state.gameHost);
export const useGameHostConfigOpt = () => select(state => state.gameHost.config);
export const useGameHostConfig = () => useGameHostConfigOpt()!;
export const useTeamsHost = () => select(state => state.gameHost.teams);

export const useContext = () => React.useContext(ClientContext)!;
export const useTriviaContext = () => React.useContext(TriviaContext)!;
export const useHostContext = () => React.useContext(HostContext)!;