import * as isEmail from 'isemail';
// @ts-ignore
import isImageUrl from 'is-image-url';
import isUrl from 'is-url';

// prevent unbounded inputs
const maxInputLen = 512;

export function isId(id: string) {
	return !!id.match(/^[A-Fa-f0-9]{24}$/);
}

function invalidateLength(str: string, min: number, max: number, fieldName: string) {
	if (!str)
		return `'${fieldName}' is required`;

	if (str.length < min)
		return `'${fieldName}' must be at least ${min} characters`;

	if (str.length > max)
		return `'${fieldName}' must be at most ${max} characters`;

	return undefined;
}

export const maxEmailLen = maxInputLen;
export function email(email: string) {
	let inval = invalidateLength(email, 1, maxEmailLen, "Email");
	if (inval)
		return inval;

	if (!isEmail.validate(email))
		return "'Email' must be a valid email address";

	return undefined;
}

export const maxUrlLen = maxInputLen;
export function imageUrl(url: string, name: string) {
	let inval = invalidateLength(url, 1, maxUrlLen, name);
	if (inval)
		return inval;

	if (!isImageUrl(url))
		return `'${name}' must be a valid image url`;

	return undefined;
}

export function url(url: string, name: string) {
	let inval = invalidateLength(url, 1, maxUrlLen, name);
	if (inval)
		return inval;

	if (!isUrl(url))
		return `${name} must be a valid url`;

	return undefined;
}

function invalidatePassword(password: string, min: number, max: number) {
	// TODO: better password requirements
	if (password.match(/\s/))
		return "'Password' must not contain whitespace";

	return invalidateLength(password, min, max, "Password");
}

export const maxUserPassLen = 64;
export function userPass(password: string) {
	return invalidatePassword(password, 6, maxUserPassLen);
}

export const maxUserNameLen = 32;
export function userName(name: string) {
	return invalidateLength(name.trim(), 3, maxUserNameLen, "User Name");
}

export const maxInitialsLen = 2;
export function initials(initials: string) {
	if (!initials.match(/^[A-Za-z]{2}$/))
		return "'Initials' must be 2 letters";

	return undefined;
}

export const maxTeamNameLen = 64;
export function teamName(teamName: string) {
	let lower = teamName.trim().toLowerCase();
	if (lower === 'host' || lower === 'no team' || lower === 'no teams' || lower === 'no other teams')
		return "'Team Name' cannot be '" + teamName + "'";

	return invalidateLength(teamName.trim(), 3, maxTeamNameLen, "Team Name");
}

export const maxTeamPassLen = 32;
export function teamPass(password: string) {
	if (!password)
		return undefined; // allow no password

	return invalidatePassword(password, 4, maxTeamPassLen);
}

export function gameTitle(title: string) {
	return invalidateLength(title, 4, 64, "Game Title");
}

export function subRoundTitle(title: string) {
	return invalidateLength(title, 1, 64, "Sub Round Title");
}

export const maxAnswerLen = 64;
export function answer(answer: string) {
	return invalidateLength(answer, 1, maxAnswerLen, "Answer");
}

export const maxQuestionLen = 256;
export function question(question: string) {
	return invalidateLength(question, 1, maxQuestionLen, "Question");
}

export function pointValue(value: number) {
	if (!Number.isInteger(value) || value <= 0)
		return "'Point Value' must be a positive integer";

	// TODO: who knows, keep it reasonable for now
	if (value > 100)
		return "'Point Value' must be less than 100";

	return undefined;
}