export const PreGame = 'pre-game';
export const PostGame = 'post-game';
export const Submit = 'submit';
export const Grade = 'grade';
export const Review = 'review';
export const Intermission = 'intermission';

export const SubPre = 'sub-pre';
export const SubIntra = 'sub-intra';
export const SubPost = 'sub-post';

export type PhaseType =
	typeof PreGame |
	typeof PostGame |
	typeof Submit |
	typeof Grade |
	typeof Review |
	typeof Intermission;

export type RoundPhaseType =
	typeof Submit |
	typeof Grade |
	typeof Review;

export const asPhase = (phase: string): PhaseType | undefined => {
	if (phase === PreGame		||
		phase === PostGame		||
		phase === Submit		||
		phase === Grade			||
		phase === Review		||
		phase === Intermission	||
		false) {
		return phase;
	}

	return undefined;
};