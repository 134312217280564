import * as React from 'react';

import { ModalProps, ModalPopupController } from './modalPopupController';
import { ModalError, ModalConfirm, ModalAlert } from '../state';


export function ModalPopupView(props: ModalProps) {
    return <>
        <div className={props.className} aria-modal>
            {props.children}
        </div>
		<div className="modal-overlay" />
	</>;
}

export function ErrorPopup({ err }: { err: ModalError }) {
    return (
        <ModalPopupController className="error-modal" onClose={err.onClose}>
            <div className="error-modal-guts">
                <p>
                    {err.main}
                    <br />
                    {err.sub}
                </p>
                <div className="error-modal-buttons">
                    <button className="ok" onClick={err.onClose}>OK</button>
                </div>
            </div>
		</ModalPopupController>
	);
}

export function AlertPopup({ alert }: { alert: ModalAlert }) {
    return (
        <ModalPopupController className="error-modal" onClose={alert.onClose}>
            <div className="error-modal-guts">
                <p>{alert.msg}</p>
                <div className="error-modal-buttons">
                    <button className="ok" onClick={alert.onClose}>OK</button>
                </div>
            </div>
        </ModalPopupController>
    );
}

export function ConfirmPopup({ confirm }: { confirm: ModalConfirm }) {
    return (
        <ModalPopupController className="error-modal" onClose={confirm.deny}>
            <div className="error-modal-guts">
                <p>{confirm.msg}</p>
                <div className="error-modal-buttons">
                    <button className="yes" onClick={confirm.confirm}>YES</button>
                    <button className="no" onClick={confirm.deny}>NO</button>
                </div>
            </div>
        </ModalPopupController>
    );
}