import * as React from 'react';

import { ModalPopupView } from './modalPopup';


export interface ModalProps {
	className: string,
	children?: React.ReactNode,
	onClose: () => void;
}

export function ModalPopupController(props: ModalProps) {
	React.useEffect(() => {
		let onKeyDown = (e: KeyboardEvent) => {
			if (e.keyCode === 27)
				props.onClose();
		}

		document.addEventListener("keydown", onKeyDown, false);
		return () => { document.removeEventListener("keydown", onKeyDown, false); };
	}, [props.onClose]);

	return <ModalPopupView {...props} />
}