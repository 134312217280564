// Needed for babel runtime
import "core-js/stable";
import "regenerator-runtime/runtime";

require('../../public/css/normalize.css');
require('../../public/css/main.css');
import "react-datepicker/dist/react-datepicker.css";


import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as ReactRedux from 'react-redux';
import * as Redux from 'redux';
import * as ReduxThunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import { State } from './state';
import { ActionTypes } from "./actions";
import { Client, ClientContext } from './context';
import { rootReducer } from './reducer';
import { RootController } from './components/root';


export declare type TriviaStore = Redux.Store<State, ActionTypes>;

export function setup<T extends Client>(
	contextFactory: (store: TriviaStore) => T,
	ContextProvider: React.Provider<T | undefined>,
	AppComponent: () => JSX.Element,
) {
	const store = Redux.createStore(rootReducer, composeWithDevTools(Redux.applyMiddleware(ReduxThunk.default)));
	const context = contextFactory(store);

	ReactDOM.render(
		<ReactRedux.Provider store={store}>
			<ContextProvider value={context}>
				<ClientContext.Provider value={context}>
					<RootController>
						<AppComponent />
					</RootController>
				</ClientContext.Provider>
			</ContextProvider>
		</ReactRedux.Provider>,
		document.getElementById('root')
	);

	context.initialize();
}

export function setupTrivial(AppComponent: () => JSX.Element) {
	ReactDOM.render(
		<AppComponent />,
		document.getElementById('root')
	);
}