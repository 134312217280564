import * as React from 'react';

import * as validate from '../../shared/validation';
import { LoginModalProps } from './loginModalController';
import { ValidatedFormProps, ValidatedForm } from './form';
import { config } from '../config';


function loginButtonProps(isRegister: boolean, props: LoginModalProps) {
	let isSubmit = (isRegister === props.isRegister);
	let type: 'submit' | 'button' = isSubmit ? 'submit' : 'button';
	let regName = (isReg: boolean) => isReg ? 'register' : 'login';
	return {
		key: regName(props.isRegister) + "-" + regName(isRegister),
		disabled: props.isPending,
		onClick: isSubmit ? undefined : props.toggleFn,
		type: type,
		className: isSubmit ?  "login-button-selected"  : undefined,
	}
}

export function LoginModalView(props: LoginModalProps) {
	let loginText = (props.isPending && !props.isRegister) ? "pending..." : "login";
	let registText = (props.isPending && props.isRegister) ? "pending..." : "register";
	let loginProps = loginButtonProps(false, props);
	let registerProps = loginButtonProps(true, props);

	let formProps: ValidatedFormProps = {
		inputWrapperClass: "login-container clearfix",
		inputClass: "login-field",
		errorClass: "error-text",
		globalErr: props.error,
		disabled: props.isPending,
		submitFn: props.submitFn,
		inputs: [
			{
				placeholder: "email address:",
				maxLength: validate.maxEmailLen,
				validateFn: validate.email,
			},
			{
				placeholder: "password:",
				type: "password",
				maxLength: validate.maxUserPassLen,
				validateFn: validate.userPass,
			},
			{
				placeholder: "username:",
				hidden: !props.isRegister,
				maxLength: validate.maxUserNameLen,
				validateFn: validate.userName,
			},
			{
				placeholder: "display initials:",
				hidden: !props.isRegister,
				maxLength: validate.maxInitialsLen,
				validateFn: validate.initials
			},
		],
	};

	return (
		<div className="landing-container clearfix">
			<h1>Thrillhouse Trivia</h1>
			<p>To join a game, please login or register a new account.</p>
			<ValidatedForm {...formProps}>
				<div className="landing-buttons">
					<div className="register-button" >
						<button {...loginProps}>{loginText}</button>
					</div>
					<div className="register-button">
						<button {...registerProps}>{registText}</button>
					</div>
				</div>
			</ValidatedForm>
			<div className="contact-us">
				<a className="contact-us-button" target="_blank" href={`mailto:${config.supportEmail}`}>
					questions or problems? get in touch!
				</a>
			</div>
		</div>
	);
};