declare var __TEST_DEFINE: string;

const isDev = (process.env.NODE_ENV === 'development');
export const config = {
	DEV: isDev,
	testDefine: __TEST_DEFINE,

	supportEmail: "ThrillhouseTrivia@gmail.com",
	socketDebounceTime: 1000,

	logVerbose: false,
	debugCtrls: false,
	hideTestGames: !isDev,
};