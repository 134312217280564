import * as React from 'react';

import * as Net from '../shared/net';
import * as Phases from '../shared/phases';
import * as Async from './async';
import { Client } from './context';
import { patchGameHost, patchGameConfig, setGameId } from './actions';
import { isId } from '../shared/validation';


const gameIdParam = 'gid';

export class HostClient extends Client {
	private gameId?: string = undefined;

	private async checkForGameId() {
		const urlParams = new URLSearchParams(window.location.search);
		const gid = urlParams.get(gameIdParam);
		if (gid && isId(gid)) {
			this.gameId = gid;
			await this.onGameId();
		}
	}

	public async joinGameHost(gameId: string) {
		const params = new URLSearchParams(window.location.search);
		params.set(gameIdParam, gameId);
		window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
		this.gameId = gameId;
		await this.onGameId();
	}

	private async onGameId() {
		this.store.dispatch(setGameId(this.gameId!));
		let res2 = await this.api.getGameHost(this.gameIdProp());
		if (!res2.ok) {
			this.handleError(Async.AppError, "error loading game data");
			return;
		}

		let gameHost = res2.response;
		this.store.dispatch(patchGameHost(gameHost));

		if (gameHost.running)
			await this.onGameRunning();
	}

	private async onGameRunning() {
		let state = this.store.getState();
		if (state.user!.currentGame !== this.gameId) {
			if (state.user!.currentGame) {
				await this.leaveGame();
			}

			let res = await this.joinGame(this.gameId!);
			if (!res.ok) {
				this.handleError(Async.AppError, "error joining game");
				return;
			}
		}

		await this.connectSockets();
	}

	protected async onLoggedIn(user: Net.User) {
		await this.checkForGameId();
		if (!this.gameId) {
			console.log("no game id");
			// TODO: only show host game if not admin
			this.loadGamesList();
		}
	}

	public gameIdProp(): Net.GameId {
		if (!this.gameId)
			this.handleError(Async.AppError, "missing game id");

		return { gameId: this.gameId! }
	}

	public async submitComplaintReview(team: Net.TeamMeta, qid: Net.QuestionId, correct: boolean) {
		return this.api.submitComplaintReview({
			...qid,
			...this.gameIdProp(),
			correct,
			teamId: team.id,
		});
	}

	// TODO: make interesting
	public setPhase(phase: Net.Phase) {
		return this.api.setPhase({ phase, ...this.gameIdProp() });
	}

	public updateStream(stream: string) {
		return this.api.updateStream({ stream, ...this.gameIdProp() });
	}

	public async endGame(): Promise<Async.AsyncResponse<{}>> {
		let self = this;
		let c = await this.modalConfirm("warning: this will delete all teams, answers and scores, and close the game");
		if (!c)
			return Async.CancelError;

		return await self.api.closeGame(this.gameIdProp());
	}

	public async launchGame() {
		let res = await this.api.launchGame(this.gameIdProp());
		if (res.ok) {
			this.store.dispatch(patchGameHost({ running: true }));
			await this.onGameRunning();
		}

		return res;
	}

	public async patchConfig(config: Net.GameConfigPatch) {
		let res = await this.api.patchGameConfig(config);
		if (res.ok)
			this.store.dispatch(patchGameConfig(config));

		return res;
	}

	public setConfigRounds(rounds: Net.Round[]) {
		return this.patchConfig({ rounds, ...this.gameIdProp() });
	}

	public setConfigDate(date: Date) {
		let scheduledStart = date.valueOf();
		return this.patchConfig({
			scheduledStart, ...this.gameIdProp()
		});
	}

	// ---- UI Bindings ----
	public bindSetConfigTitle() {
		let self = this;
		return async (title: string) => {
			return (await self.patchConfig({ title, ...this.gameIdProp() })).ok;
		}
	}

	public bindSetConfigStreamUrl() {
		let self = this;
		return async (streamUrl: string) => {
			let stream = { url: streamUrl, width: null, height: null };
			return (await self.patchConfig({ stream, ...this.gameIdProp() })).ok;
		}
	}

	public bindCloseGame() {
		let self = this;
		return async () => (await self.endGame()).ok;
	}
}

export const HostContext = React.createContext<HostClient | undefined>(undefined);