import * as React from 'react';


class WPImage {
	public readonly src: string;
	public constructor(src: string) {
		this.src = src;
	}

	public asImage(className?: string) {
		return <img className={className} src={this.src} />
	}

	public asBackground() {
		return `url(${this.src})`;
	}
}

// @ts-ignore
import _Pending from '../../../public/img/pending.gif';
export const Pending = new WPImage(_Pending);

// @ts-ignore
import _BarPending from '../../../public/img/bar-pending.gif';
export const BarPending = new WPImage(_BarPending);

// @ts-ignore
import _Cloudpoint from '../../../public/img/Cloudpoint.png';
export const Cloudpoint = new WPImage(_Cloudpoint);

// @ts-ignore
import _LandingPageIcon from '../../../public/img/landing-page-icon.png';
export const LandingPageIcon = new WPImage(_LandingPageIcon);

// @ts-ignore
import _LandingPageLogo from '../../../public/img/landing-page-logo.png';
export const LandingPageLogo = new WPImage(_LandingPageLogo);