import * as React from 'react';

import { setupTrivial } from './setup';
import { LandingPageIcon } from './components/images';
import { config } from './config';

export function LandingPage() {
	return <>
        <div className="landing-page">
            <div className="landing-page-header clearfix">
                {LandingPageIcon.asImage("landing-page-tt-header-icon")}
                <a href="/trivia.html" className="landing-page-login-button">login</a>
            </div>
            <div className="landing-page-hero-container-box clearfix">
                <div className="landing-page-hero-container clearfix">
                    <h1>Free Team Trivia.</h1>
                    <h1>Thursday Nights @ 8pm <span className="landing-page-timezone">(cst)</span>.</h1>
                    <a href="/trivia.html" className="landing-page-get-started-button">
                        get started
                    </a>
                </div>
                <div className="landing-page-logo clearfix">
                    <h1 className="landing-page-hero-logo-text">Thrill</h1>
                    <h1 className="landing-page-hero-logo-text landing-page-hero-logo-text-second-line">house</h1>
                </div>
            </div>
            <div className="landing-page-footer clearfix">
                <h2>Want to host your own game?</h2>
                <a target="_blank" href={`mailto:${config.supportEmail}`} className="landing-page-contact-button">
                        let's chat
                </a>
            </div>
        </div>
	</>;
}

setupTrivial(LandingPage);
