import * as React from 'react';
import { useAsyncFn } from 'react-use';

import * as Net from '../../shared/net';


export interface ChildrenProp {
	children?: React.ReactNode;
}

export function rftExitAnim(className: string, duration: number) {
	return (e: HTMLElement, index: number, removeElement: () => void) => {
		e.classList.add(className);
		setTimeout(removeElement, duration);
	}
}

export function rftEnterAnim(className: string, duration: number, isOpacityAnim: boolean, classNameDone?: string,) {
	return (e: HTMLElement) => {
		if (!isOpacityAnim)
			e.style.opacity = "1";
		e.classList.add(className);

		setTimeout(() => {
			if (classNameDone)
				e.classList.add(classNameDone);
			if (isOpacityAnim)
				e.style.opacity = "1";
			e.classList.remove(className);
		}, duration);
	}
}

export const rftFadeInDelay = rftEnterAnim("fade-in-delay", 800, true);
export const rftFadeOut = rftExitAnim("fade-out", 300);
export const rftFadeInQuick = rftEnterAnim("fade-in-quick", 250, true);
export const rftFadeOutQuick = rftExitAnim("fade-out-quick", 250);

export interface Pos {
	x: number;
	y: number;
}

export function useRefPosition<T extends HTMLElement>(ref: React.RefObject<T>): Pos {
	if (ref.current) {
		let rect = ref.current.getBoundingClientRect();
		return {
			x: (rect.left + rect.width * 0.5),
			y: (rect.top + rect.height * 0.5),
		};
	}

	return { x: 0, y: 0 };
}

export type AnchorPoint = 'tl' | 'bl' | 'br' | 'tr';

export function getFixedAnchorStyle(pos: Pos, anchor: AnchorPoint) {
	function px(n: number) { return `${n}px`; }

	let style: React.CSSProperties = {};
	if (anchor.charAt(1) === 'l')
		style.left = px(pos.x);
	else
		style.right = px(pos.x);

	if (anchor.charAt(0) === 't')
		style.top = px(pos.y);
	else
		style.bottom = px(pos.y);

	return style;
}

export function pad(num: number, len: number) {
	let str = "" + num;
	while (str.length < len)
		str = "0" + str;
	return str;
}

// TODO: make better
export function formatDate(dateNum: number) {
	let date = new Date(dateNum);
	let hours = date.getHours();
	let ampm = "am";
	if (hours >= 12) {
		hours -= 12;
		ampm = "pm";
	}
	if (hours == 0)
		hours = 12;

	return `${date.getMonth() + 1}/${date.getDate()} ${hours}:${pad(date.getMinutes(), 2)} ${ampm}`;
}

export function ordinal(n: number) {
	switch (n) {
		case 1: return '1st';
		case 2: return '2nd';
		case 3: return '3rd';
		default: return `${n}th`;
	}
}

export function visible(f: boolean): React.CSSProperties {
	return {
		'visibility': f ? 'visible' : 'hidden'
	}
}

export function display(d: boolean): React.CSSProperties {
	return d ? {} : { 'display': 'none' };
}

export function keyForQid(qid: Net.QuestionId) {
	return `${qid.round}:${qid.question}`;
}

export function sortByProp<T>(mp: (t: T) => any, descending?: boolean) {
	let sign = descending ? -1 : 1;
	return (ta: T, tb: T) => {
		let a = mp(ta);
		if (typeof a === "string")
			a = a.toUpperCase();

		let b = mp(tb);
		if (typeof b === "string")
			b = b.toUpperCase();

		let ret = 0;
		if (a < b)
			ret = -1;
		else if (a > b)
			ret = +1;

		return ret * sign;
	}
}

export function sortMulti<T>(sorts: ((ta: T, tb: T) => number)[]) {
	return (ta: T, tb: T) => {
		for (let sort of sorts) {
			let v = sort(ta, tb);
			if (v !== 0)
				return v;
		}

		return 0;
	}
}

// zero indexed
export function letterForNum(num: number) {
	const charCodeA = 'a'.charCodeAt(0);
	if (num < 0 || num >= 26)
		return '?';

	return String.fromCharCode(charCodeA + num);
}

export const Chars = {
	nbsp: <>&nbsp;</>,
	checkMark: <>&#10003;</>,
	xMark: <>&#10007;</>,
	lock: <>&#128274;</>,
	triRight: <>&#9656;</>,
	triDown: <>&#9662;</>,
}

export interface QGrade {
	isCorrect: boolean;
	isIncorrect: boolean;
}

export function GradeForAnswer(answer?: Net.TeamAnswer) {
	let correct = answer?.correct ?? null;
	return {
		isCorrect: (correct !== null) && correct,
		isIncorrect: (correct !== null) && !correct,
	}
}

export function getTeamName(teams: Map<string, Net.TeamMeta>, teamId?: string | null) {
	if (!teamId)
		return undefined;

	return teams.get(teamId)?.name;
}

export interface AsyncButtonProps {
	children?: React.ReactNode;
	onClick: () => Promise<boolean>;
	roc?: boolean; // Revert on completion
	disabled?: boolean;
	className?: string;
}

export function AsyncButton({ children, onClick, roc, disabled, className }: AsyncButtonProps) {
	let [state, action] = useAsyncFn(onClick, [onClick]);
	if (state.loading || (!roc && state.value))
		return <button className={className} disabled><p>Pending...</p></button>
	else
		return <button className={className} onClick={action} disabled={!!disabled}><p>{children}</p></button>;
}

export const connectErrHeight = 35;
	

export function scriptLoader(url: string) {
	let script: HTMLScriptElement;
	let loaded = false;
	let awaiters: (() => void)[] = [];

	let loadScript = () => {
		script = document.createElement<'script'>('script');
		script.src = url;
		script.onload = () => {
			console.log("loaded script: " + url);
			for (let a of awaiters)
				a();
		}
		document.body.appendChild(script);
	}

	return {
		load: () => {
			return new Promise((res, rej) => {
				try {
					if (loaded)
						res();
					else {
						awaiters.push(res);

						if (!script) {
							loadScript();
						}
					}
				} catch (err) {
					rej(err);
				}
			});
		}
	}
}
