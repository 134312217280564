import * as React from 'react';
import { useAsyncFn } from 'react-use';

import { LoginModalView } from './loginModal';
import { useContext } from '../selectors';


export interface LoginModalProps {
	isRegister: boolean;
	isPending: boolean;
	error?: string;

	submitFn: (e: string, p: string, u: string, i: string) => void;
	toggleFn: () => void;
}

export function LoginModalController() {
	let ctxt = useContext();

	let [isRegister, setIsRegister] = React.useState(false);
	let [error, setError] = React.useState<string | undefined>(undefined);

	let submit = async (email: string, pass: string, username: string, initials: string) => {
		setError(undefined);

		let promise = isRegister
			? ctxt.register(email, pass, username, initials)
			: ctxt.login(email, pass);

		let res = await promise;
		if (!res.ok) {
			setError(res.message ?? "Server Error");
			return false;
		}

		return true;
	}

	let [submitState, doSubmit] = useAsyncFn(submit, [isRegister]);

	let props: LoginModalProps = {
		isRegister: isRegister,
		isPending: submitState.loading || (submitState.value === true),
		error: error,

		toggleFn: () => { setIsRegister(!isRegister), setError(undefined); },
		submitFn: (email, pass, username, initials) => { doSubmit(email, pass, username, initials) },
	}

	return <LoginModalView {...props} />
}