import { Static } from 'runtypes';

import * as Rt from './types';

export const Action = {
	SetGame: 'set_game',
	PatchGame: 'patch_game',
	PatchHost: 'patch_host',
	Chat: 'chat',
	Alert: 'alert',
	EndGame: 'end_game',
};

export const ComplaintStatus = {
	Pending: Rt.ComplaintPending,
	Reviewed: Rt.ComplaintReviewed,
}

export type UserMeta = Static<typeof Rt.UserMeta>;
export type User = Static<typeof Rt.User>;
export type Chat = Static<typeof Rt.Chat>;
export type UserAlert = Static<typeof Rt.UserAlert>;
export type Team = Static<typeof Rt.Team>;
export type TeamAnswer = Static<typeof Rt.TeamAnswer>
export type TeamRound = Static<typeof Rt.TeamRound>;
export type TeamScore = Static<typeof Rt.TeamScore>;
export type TeamMeta = Static<typeof Rt.TeamMeta>;
export type Game = Static<typeof Rt.Game>;
export type Patch = Static<typeof Rt.Patch>;
export type GameHostPatch = Static<typeof Rt.GameHostPatch>

export type PreGamePhase = Static<typeof Rt.PreGamePhase>;
export type PostGamePhase = Static<typeof Rt.PostGamePhase>;
export type SubmitPhase = Static<typeof Rt.SubmitPhase>;
export type GradingPhase = Static<typeof Rt.GradingPhase>;
export type ReviewPhase = Static<typeof Rt.ReviewPhase>;
export type IntermissionPhase = Static<typeof Rt.IntermissionPhase>;
export type Phase = Static<typeof Rt.Phase>;
export type RoundPhase = Static<typeof Rt.RoundPhase>;

export type QuestionBase = Static<typeof Rt.QuestionBase>;
export type QuestionText = Static<typeof Rt.QuestionText>;
export type Question = Static<typeof Rt.Question>;
export type SubRound = Static<typeof Rt.SubRound>;
export type Round = Static<typeof Rt.Round>;
export type StreamInfo = Static<typeof Rt.StreamInfo>
export type GameConfigPublic = Static<typeof Rt.GameConfigPublic>
export type GameConfig = Static<typeof Rt.GameConfig>

export type GameMeta = Static<typeof Rt.GameMeta>;

// ---- To Server ----
export type QuestionId = Static<typeof Rt.QuestionId>;
export type SubmitAnswer = Static<typeof Rt.SubmitAnswer>;
export type SubmitGrade = Static<typeof Rt.SubmitGrade>;
export type SubmitComplaint = Static<typeof Rt.SubmitComplaint>;
export type SubmitRound = Static<typeof Rt.SubmitRound>;
export type SendChat = Static<typeof Rt.SendChat>;
export type JoinGame = Static<typeof Rt.JoinGame>;
export type CreateTeam = Static<typeof Rt.CreateTeam>;
export type JoinTeam = Static<typeof Rt.JoinTeam>;
export type SetTeamOwner = Static<typeof Rt.SetTeamOwner>;
export type SetTeamLFG = Static<typeof Rt.SetTeamLFG>;
export type Credentials = Static<typeof Rt.Credentials>;
export type Registration = Static<typeof Rt.Registration>;
export type ChangeName = Static<typeof Rt.ChangeName>;
export type ReportError = Static<typeof Rt.ReportError>;

export type GameId = Static<typeof Rt.GameId>;
export type GetGameHost = Static<typeof Rt.GetGameHost>;
export type GameConfigPatch = Static<typeof Rt.GameConfigPatch>;
export type LaunchGame = Static<typeof Rt.LaunchGame>;
export type CloseGame = Static<typeof Rt.CloseGame>;
export type SetPhase = Static<typeof Rt.SetPhase>;
export type SetStream = Static<typeof Rt.SetStream>;
export type SubmitComplaintReview = Static<typeof Rt.SubmitComplaintReview>;


export const Qid = (round: number, question: number): QuestionId => ({ round, question });