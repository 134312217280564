import wu from 'wu';

import * as Net from '../shared/net'
import { ActionTypes, ActionTypeSetUser, ActionTypeSetGame, ActionTypePatchGame, ActionTypeAddChat, ActionTypePatchGameHost, ActionTypeSetGamesList, ActionTypeReset, ActionTypePushModal, ActionTypePopModal, PatchGameConfigAction, ActionTypePatchGameConfig, ActionTypeSocketStatus, ActionTypeSetGameId } from './actions';
import { State, initialState } from './state';


function patchMap<V>(orig: Map<string, V>, patch: [string, V | null][]) {
	let patched = new Map(orig);
	for (let [key, val] of patch) {
		if (val)
			patched.set(key, val);
		else
			patched.delete(key);
	}

	return patched;
}

import { Game } from './state';

export function rootReducer(state: State | undefined, action: ActionTypes): State {
	if (state === undefined) {
		return initialState;
	}

	try {
		switch (action.type) {
			case ActionTypeReset:
				{
					return {
						...initialState,
						initialLoad: false
					}
				}
			case ActionTypeSetGameId:
				{
					return {
						...state,
						gameId: action.id,
					}
				}
			case ActionTypeSocketStatus:
				{
					return {
						...state,
						socketErr: !action.ok,
					}
				}
			case ActionTypePushModal:
				{
					return {
						...state,
						modals: [...state.modals, action.modal],
					}
				}
			case ActionTypePopModal:
				{
					return {
						...state,
						modals: state.modals.filter((m, i) => i > 0),
					}
				}
			case ActionTypeSetUser:
				{
					return {
						...state,
						user: action.user,
						initialLoad: false
					};
				}
			case ActionTypeSetGamesList:
				{
					return {
						...state,
						gamesList: action.gamesList
					}
				}
			case ActionTypeSetGame:
				{
					let game: Game | null = null;
					if (action.game) {
						let teams = new Map<string, Net.TeamMeta>();
						for (let [id, team] of action.game.teams)
							teams.set(id, team);

						let chat: Net.Chat[] = [];

						let user = state.user!;
						if (user.currentGame != null && user.currentGame !== action.game.id)
							console.warn("Reducer[Set Game] - user already has different game");

						state = {
							...state,
							user: {
								...user,
								currentGame: action.game.id,
							}
						}

						game = {
							...action.game,
							teamChat: action.game.teamChat ?? [],
							teams: teams,
						};
					} else {
						state = {
							...state,
							user: {
								...state.user!,
								currentGame: null,
							},
							gameHost: {
								...state.gameHost,
								running: false,
								teams: new Map<string, Net.Team>(),
							}
						}
					}

					return {
						...state,
						game: game,
						gamesList: null
					};
				}
			case ActionTypePatchGame:
				{
					if (!state.game) {
						console.warn("Reducer[Patch Game] - received before set game");
						return state;
					}

					let patch = action.patch;
					let game = state.game;

					if (patch.team !== undefined) {
						game = {
							...game,
							team: patch.team,
							teamChat: patch.team === null ? [] : game.teamChat,
						}
					}

					if (patch.phase !== undefined) {
						game = {
							...game,
							phase: patch.phase
						}
					}

					if (patch.teams) {
						game = {
							...game,
							teams: patchMap(game.teams, patch.teams),
						}

						if (state.game.team) {
							let team = state.game.team;
							let meta = wu(patch.teams).find(([id, t]) => id === team.id);

							if (meta) {
								game = {
									...game,
									team: {
										...team,
										...meta[1],
									}
								}
							}
						}

						if (state.gameHost.teams) {
							let teams = new Map(state.gameHost.teams);
							for (let [id, team] of patch.teams) {
								if (!team)
									teams.delete(id);
								else {
									let prev = teams.get(id);
									if (!prev) {
										console.warn("Reducer[Patch Game]:Host - team meta with no team");
										continue;
									}

									teams.set(id, {
										...prev,
										...team,
									});
								}
							}

							state = {
								...state,
								gameHost: {
									...state.gameHost,
									teams: teams,
								}
							}
						}
					}

					return {
						...state,
						game: game
					};
				}
			case ActionTypePatchGameHost:
				{
					if (!state.gameHost) {
						console.warn("Reducer[Patch Game Host] - patch received before set game host");
						return state;
					}

					let gameHost = state.gameHost;
					if (action.patch.running !== undefined)
						gameHost = {
							...gameHost,
							running: action.patch.running,
						}

					if (action.patch.teams !== undefined) {
						gameHost = {
							...gameHost,
							teams: patchMap(gameHost.teams, action.patch.teams),
						}
					}

					if (action.patch.config !== undefined) {
						gameHost = {
							...gameHost,
							config: action.patch.config,
						}
					}

					return {
						...state,
						gameHost: gameHost
					};
				}
			case ActionTypePatchGameConfig:
				{
					if (!state.gameHost.config) {
						console.warn("Reducer[Patch Game Host] - config patch received before set game host");
						return state;
					}

					return {
						...state,
						gameHost: {
							...state.gameHost,
							config: {
								...state.gameHost.config,
								...action.patch,
							}
						}
					}
				}
			case ActionTypeAddChat:
				{
					if (!state.game) {
						console.warn("Reducer[Add Chat] - chat received before set game");
						return state;
					}

					let game = state.game;
					if (action.chat.team) {
						game = {
							...game,
							gameChat: [...game.gameChat, action.chat]
						}
					}
					else {
						game = {
							...game,
							teamChat: [...game.teamChat, action.chat]
						}
					}

					return {
						...state,
						game: game
					};
				}
			default:
				{
					console.error("Reducer - unknown action: " + action);
					return state;
				}
		}
	} catch (err) {
		console.error("Reducer - exception caught");
		console.error(err);
		return state;
	}
};