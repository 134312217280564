import * as React from 'react';
import * as ReactRedux from 'react-redux';

import { State, ModalErrorType, ModalConfirmType, ModalAlertType } from '../state';
import { useHasConnectionErr } from '../selectors';
import { ClientContext } from '../context';
import { LoginModalController } from './loginModalController';
import { ChildrenProp, connectErrHeight } from './util';
import { HeaderErr } from './headerError';
import { ErrorPopup, ConfirmPopup, AlertPopup } from './modalPopup';


export function AppLoading() { return <h1>Loading...</h1>; }

function ModalDispatch() {
	let modal = ReactRedux.useSelector((state: State) => {
		return state.modals.length > 0 ? state.modals[0] : undefined;
	});

	if (!modal)
		return null;

	if (modal.type === ModalErrorType)
		return <ErrorPopup err={modal} />
	else if (modal.type === ModalAlertType)
		return <AlertPopup alert={modal} />
	else if (modal.type === ModalConfirmType)
		return <ConfirmPopup confirm={modal} />
	else {
		//console.warn("unknown modal type: " + modal.type);
		return null;
	}
}


interface ErrorState {
	fatalError: boolean;
}

class RootErrorBoundary extends React.Component<ChildrenProp, ErrorState> {
	static contextType = ClientContext;
	context!: React.ContextType<typeof ClientContext>;

	constructor(props: ChildrenProp) {
		super(props);
		this.state = { fatalError: false };
	}

	static getDerivedStateFromError(error: Error) {
		return { fatalError: true }
	}

	componentDidCatch(error: Error, info: React.ErrorInfo) {
		this.context!.handleReactError(error, info);
	}

	render() {
		return this.state.fatalError ? null : this.props.children;
	}
}

function HeightScaler({ children }: ChildrenProp) {
	let hasErr = useHasConnectionErr();
	const [windowHeight, setHeight] = React.useState(window.innerHeight);
	const updateHeight = () => setHeight(window.innerHeight);

	React.useEffect(() => {
		window.addEventListener("resize", updateHeight);
		return () => window.removeEventListener("resize", updateHeight);
	});

	let height = windowHeight - (hasErr ? connectErrHeight : 0);
	let heightStyle = { 'height': height };

	return (
		<div className="columns-container clearfix" style={heightStyle}>
			{children}
		</div>
	);
}

export function RootController({ children }: ChildrenProp) {
	let state = ReactRedux.useSelector((state: State) => ({
		loading: state.initialLoad,
		user: state.user
	}));

	let content: React.ReactNode;
	if (state.loading)
		content = <AppLoading />;
	else if (!state.user)
		content = <LoginModalController />
	else
		content = children;

	return <>
		<RootErrorBoundary>
			<HeaderErr isVisible={true} />
			<HeightScaler>
				{content}
			</HeightScaler>
		</RootErrorBoundary>
		<ModalDispatch />
	</>;
}