import * as React from 'react';

import { useHasConnectionErr } from '../selectors';
import { visible, connectErrHeight } from './util';
import { Pending } from './images';


export function HeaderErr({ isVisible }: { isVisible: boolean }) {
	let hasErr = useHasConnectionErr();
	let margin = hasErr ? 0 : -connectErrHeight;
	let style = {
		...visible(isVisible),
		"marginTop": (margin + "px"),
	}

	return (
		<div className="error-top" style={style}>
			<p>Connection lost to server, attempting to reconnect</p>
			<div className="error-top-spinner-box">
				{Pending.asImage("error-top-close")}
			</div>
		</div>
	);
}
